require('slick-carousel');

// Custom
$(function () {
    "use strict";

    $('.property-slide').slick({
        slidesToShow: 3,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            }
        ]
    });
});

// Testimonials
$(function () {
    "use strict";

    $('.testimonials').slick({
        nextArrow: $('.testimonials-slider__right'),
        prevArrow: $('.testimonials-slider__left'),
        slidesToShow: 3,
        arrows: true,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: false,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            }
        ]
    });
});
