'use strict';

// Code for manual and automated search toggle
$(document).ready(function () {
  const buttonSelector = 'button[data-toggle]';
  const listItemSelector = 'li[data-toggle]';
  const contentSelector = '.content[data-content]';

  const buttons = document.querySelectorAll(buttonSelector);
  const listItems = document.querySelectorAll(listItemSelector);
  const contents = document.querySelectorAll(contentSelector);

  const handleToggle = (event) => {
    const button = event.target.closest(buttonSelector);

    if (!button) return;

    const targetId = button.getAttribute('data-toggle');

    buttons.forEach(btn => btn.classList.remove('button-active'));
    listItems.forEach(item => item.classList.remove('list-active'));
    contents.forEach(content => content.classList.replace('d-block', 'd-none'));

    button.classList.add('button-active');
    button.closest('li').classList.add('list-active');

    const targetContent = document.querySelector(`.content[data-content="${targetId}"]`);
    if (targetContent) {
      targetContent.classList.replace('d-none', 'd-block');
    }
  };

  document.body.addEventListener('click', handleToggle);
});

